<template lang="pug">
.page-w
  .total-box
    Box(h="7rem", className="my-item-box", v-for="(item,index) in totalList" :key="index")
      .total-box-item
        countTo.card-panel-num(
          :startVal="0",
          :decimals="(item.prop==='averageFinishCaseTime' || item.prop==='simpleIndemnityRate' || item.prop==='finishCaseRate')?2:0"
          :suffix="(item.prop==='simpleIndemnityRate' || item.prop==='finishCaseRate')?'%':''"
          :endVal="totalNumber[Object.keys(item)[0]]",
          :duration="1800"
        )
      span.labe {{ item.label }}
  .ech-box 
    .left-box
      Box(className="my-item-box2")
        .title-d.box-top-title
          MTitleI(title="赔案原因人次占比结构")
        #eChar
          PieEchart(
            v-if='!noDataShow && reFresh'
            v-bind="pieOpts"
            :seriesData="personTimeDivisionList"
          )
          NoData(v-if='noDataShow' :typeEchar="'pieChart'")
      Box(className="my-item-box2") 
        .title-d.box-top-title
          MTitleI(title="月理赔案件数柱状图")
        NoData(v-if='noDataShow2' :typeEchar="'histogram'")
        #eChar1(v-else)
          BarEchart(
            cid="eChar1",
            v-bind="barOpts",
            yAxisName="案件数(件)",
            :xAxisData="caseDivisionList.xData",
            :seriesData="caseDivisionList.seriesData"
          )
    .center-box
      .btn-box(
        :style="{ justifyContent: cityNumber != cityNumberFlag ? 'space-between' : 'flex-end' }"
      )
        div(@click="onBack", v-show="cityNumber!=cityNumberFlag")
          .btn-view-list 返回
        div(v-if="btnLabel", @click="onAreaData")
          .btn-view-list 
            span(v-if="btnLabel.length === 4", style="padding: 10px") {{ btnLabel }}
            span(v-if="btnLabel.length > 4") {{ btnLabel.slice(0, 3) }}
            span(v-if="btnLabel.length > 4") {{ btnLabel.slice(3) }}
      .area-list(v-show="cityNumber===330100")
        span(v-for="(item, index) in areaList") {{ index + 1 }}.{{ item }}
      #eMap
        MapEchart(
          cid="eMap",
          tooltipFormatterType="1",
          :cityNumber="cityNumber",
          :dataList="mapData",
          :cityFlagBack="dateTime"
          @click="onClickMap",
          @mouseover="onMouseoverMap"
        )
    .right-box
      Box(className="my-item-box2")
        .title-d.box-top-title
          MTitleI(title="赔案原因赔款金额占比结构")
        #eChar2
          PieEchart(
            v-if='!noDataShow1 && reFresh'
            v-bind="pieOpts",
            :seriesData="indemnityPremiumDivisionList"
          )
          NoData(v-if='noDataShow1' :typeEchar="'pieChart'")
      Box(className="my-item-box2")
        .title-d.box-top-title
          MTitleI(title="月赔款金额柱状图")
        NoData(v-if='noDataShow3' :typeEchar="'histogram'")
        #eChar3(v-else)
          BarEchart(
            cid="eChar3"
            v-bind="barOpts"
            :legendData="['赔款金额']"
            :yNameTextStyle="{padding:[0,0,0,36]}"
            :xAxisData="casePremiumDivisionList.xData"
            :seriesData="casePremiumDivisionList.seriesData"
          )
  MyModal(
    :modalShow="dialogVisible",
    :title="btnLabel",
    :modal="true",
    @onClose="onClose"
  )
    MyTable(
      className="mymo-table",
      :columns="tableColumns",
      :tableData="cityList"
      :split="true"
    )
</template>

<script>
import Box from "../../components/Box.vue";
import CountTo from "vue-count-to";
import PieEchart from "../../components/PieEchart.vue";
import BarEchart from "../../components/BarEchart.vue";
import MapEchart from "../../components/MapEchart.vue";
import MyTable from "../../components/MyTable.vue";
import NoData from "../../components/NoData.vue";
import MyModal from "../../components/MyModal.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      cityNumber: 330000, //城市code
      cityNumberFlag: 330000, //城市code
      parentCityCode: 0, //父级城市code
      onlyDistrict: false, //是否是区县地图模块
      dialogVisible: false, //modal 显示隐藏
      noDataShow: false, //无数据
      noDataShow1: false, //无数据
      noDataShow2: false, //无数据
      noDataShow3: false, //无数据
      reFresh: true,
      dateTime: 0,
      //杭州市小区域
      areaList: ["拱墅区", "上城区", "西湖区", "滨江区"],
      //页面顶部数据统计
      totalList: [
        { totalCaseCounts: 0, label: "理赔案件总数" },
        {
          totalClaimPeopleCounts: 0,
          label: "理赔总人次",
        },
        {
          indemnityTotalPremium: 0,
          label: "已赔款总金额(元)",
        },
        {
          averageFinishCaseTime: 0,
          label: "平均结案时长(天)",
          prop: "averageFinishCaseTime",
        },
        {
          simpleIndemnityRate: 0,
          label: "简单赔付率",
          prop: "simpleIndemnityRate",
        },
        { finishCaseRate: 0, label: "结案率", prop: "finishCaseRate" },
      ],
      //柱状图配置参数
      barOpts: {
        // axisPointer: {
        //   type: "cross",
        //   label: {
        //     backgroundColor: "#283b56",
        //   },
        // },
        stack: true,
        rightyAxisShow: false,
        splitLine: false,
        axisTick: true,
        minInterval: 0,
        barWidth: 16,
        yAxisName: "赔款金额(万元)",
        legendData: ["案件数"],
        yaxisLineShow: false,
        axisTick: false,
        XaxisLineShow: false,
        grid: {
          show: true,
          containLabel: true,
          borderColor: "#0A2F80",
          left: "5%",
          top: "23%",
          right: "2%",
          bottom: "0",
        },
      },
      //柱状图x轴数据
      barxAxisData: [],
      //柱状图 series data
      seriesData: [],
      pieOpts: {
        //饼图配置
        labelFormatter: "{b}{d}%",
        labelFontSize: 10,
        labelLineShow: true,
        legShow: false,
        labelShow: true,
        legOrient: "horizontal",
        labelPosition: "outside",
        radius: ["33%", "50%"],
        center: ["50%", "50%"],
        seriesItemLabel: {
          formatter: (params) => {
            return (
              "{name|" +
              params.name +
              "}\n{hr|}\n{percent|" +
              params.percent +
              "%} "
            );
          },
          distanceToLabelLine: 0,
          padding: [-2, 0, 0, 0],
          rich: {
            name: {
              color: "#fff",
              fontSize: 12,
              padding: [6, 10],
              align: "left",
            },
            percent: {
              color: "#fff",
              align: "center",
              fontSize: 12,
              padding: [5, 10],
            },
            hr: {
              borderColor: "#fff",
              width: "100%",
              borderWidth: 0.5,
              height: 0,
            },
          },
        },
        otherOptions: {
          labelLine: {
            length: 20,
            length2: 0,
          },
        },
      },
    };
  },
  components: {
    Box,
    CountTo,
    PieEchart,
    BarEchart,
    MapEchart,
    MyTable,
    MyModal,
    NoData,
  },
  watch: {
    //赔案原因赔款金额占，数据变化监听，改变noDataShow1状态用来判断是否有数据
    indemnityPremiumDivisionList(newVal) {
      if (!newVal || newVal.length === 0) {
        this.noDataShow1 = true;
      } else {
        this.noDataShow1 = false;
      }
      let dataFlag = false;
      newVal.map((item) => {
        if (item.value) {
          dataFlag = true;
        }
      });
      if (!dataFlag) {
        this.noDataShow1 = true;
      }
    },
    //赔案原因人次占比，数据变化监听，改变noDataShow状态用来判断是否有数据
    personTimeDivisionList(newVal) {
      this.reFresh= false;
      this.$nextTick(()=>{
        this.reFresh = true;
      });
      if (!newVal || newVal.length === 0) {
        this.noDataShow = true;
      } else {
        this.noDataShow = false;
      }
    },
    //月理赔案件数柱状图，数据变化监听，改变noDataShow2状态用来判断是否有数据
    caseDivisionList(newVal) {
      let newvalB = false;
      newVal.seriesData.map((item) => {
        if (item !== 0) {
          return (newvalB = true);
        }
      });
      if (!newvalB) {
        this.noDataShow2 = true;
      } else {
        this.noDataShow2 = false;
      }
    },
    //月赔款金额柱状图，数据变化监听，改变noDataShow2状态用来判断是否有数据
    casePremiumDivisionList(newVal) {
      let newvalB = false;
      newVal.seriesData.map((item) => {
        if (item !== 0) {
          return (newvalB = true);
        }
      });
      if (!newvalB) {
        this.noDataShow3 = true;
      } else {
        this.noDataShow3 = false;
      }
    },
    years(newVal) {
      this.getClaimStatisticsDetail(newVal); // 获取省数据
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
      years: (state) => state.app.years,
      totalNumber: (state) => state.claimData.totalNumber,
      personTimeDivisionList: (state) => state.claimData.personTimeDivisionList,
      indemnityPremiumDivisionList: (state) =>
        state.claimData.indemnityPremiumDivisionList,
      caseDivisionList: (state) => state.claimData.caseDivisionList,
      casePremiumDivisionList: (state) =>
        state.claimData.casePremiumDivisionList,
      cityList: (state) => state.claimData.cityList,
      mapData: (state) => state.claimData.mapData,
    }),
    btnLabel() {
      //根据市编码动态切换 按钮文字
      if (this.cityNumber === 330000) {
        return "各市数据";
      }
      if (Number(this.cityNumber.toString().slice(4)) > 0) {
        this.onlyDistrict = true;
        return "";
      }
      this.onlyDistrict = false;
      return "各区县数据";
    },
    tableColumns() {
      let columns = [
        { label: "城市", prop: "cityName" },
        { label: "案件总数", prop: "totalCaseCounts" },
        { label: "理赔总人次", prop: "totalClaimPeopleCounts" },
        {
          label: "已赔款总金额(元)",
          prop: "indemnityTotalPremium",
        },
        { label: "平均结案时长(天)", prop: "averageFinishCaseTime" },
        {
          label: "简单赔付率",
          prop: "simpleIndemnityRate",
        },
        {
          label: "结案率",
          prop: "finishCaseRate",
        },
        { label: "总保费(元)", prop: "totalPremium" },
        { label: "总投保人数", prop: "totalPeopleCounts" },
      ];
      if (this.cityNumber === 330000) {
        columns[0].label = "城市";
      } else {
        columns[0].label = "区县";
      }
      return columns;
    },
  },
  created() {
    // 判断是否为省级账号，获取省的所有数据
    if (this.userInfo.cityCode === 'C000' && this.userInfo.districtCode === 'D0000') {
      this.cityNumberFlag = 330000; // 是否展示返回按钮 33000 是浙江省的编号 省的时候没有返回按钮
      this.getClaimStatisticsDetail(this.years); // 获取省数据
    }
    // 判断是否为市的账号，获取市的所有数据
    if (this.userInfo.cityCode !== 'C000' && this.userInfo.districtCode === 'D0000') {
      // 判断是否展示返回按钮，如果是市的账号，展示市的时候，没有返回按钮
      this.cityNumberFlag = this.userInfo.cityCode;
      this.onClickMap(330000,Number(this.userInfo.cityCode),this.userInfo.cityCode); // 获取市数据
    }
    // 判断是否为市的账号，获取市的所有数据
    if (this.userInfo.districtCode !== 'D0000') {
      // 判断是否展示返回按钮，如果是区县的账号，展示区县的时候，没有返回按钮
      this.cityNumberFlag = this.userInfo.districtCode;
      this.onClickMap(Number(this.userInfo.cityCode),Number(this.userInfo.districtCode),null); // 获取区县数据
    }
  },
  methods: {
    ...mapActions(["_getClaimStatisticsDetail"]),
    getClaimStatisticsDetail(year) {
      let params = {};
      if (this.cityNumber !== 330000) {
        if (!this.isCity) {
          //区县
          params.cityCode = this.parentCityCode.toString();
          params.districtCode = this.cityNumber.toString();
        } else {
          //市
          params.cityCode = this.cityNumber.toString();
        }
      }
      params.year = year;
      this._getClaimStatisticsDetail(params);
    },
    //地图点击事件
    onClickMap(parentCityCode, value, isCity) {
      if (this.onlyDistrict) return; //区县级地图点击事件禁止
      this.isCity = isCity; //true 市 false 区县
      this.parentCityCode = parentCityCode; //父级城市或者区县 地区编码
      this.cityNumber = value; //点击当前城市的 编码
      this.getClaimStatisticsDetail(this.years); //获取页面详情数据
    },
    //地图如若模块中的事件
    onMouseoverMap(e) {},
    //返回按钮事件
    onBack() {
      this.dateTime = Date.parse(new Date());
      //修改this.cityNumber 使地图根据parentCityCode继续重新绘制
      this.cityNumber = this.parentCityCode;
      if (this.parentCityCode !== 330000) {
        this.parentCityCode = 330000;
        this.isCity = true;
      }
      this.getClaimStatisticsDetail(this.years);
    },
    //modal 关闭回调
    onClose() {
      this.dialogVisible = false;
    },
    //区县数据 个市数据
    onAreaData() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.page-w {
  display: flex;
  flex-direction: column;
}
#eMap,
#eChar,
#eChar1,
#eChar2,
#eChar3 {
  flex: 1;
  width: 100%;
}
.mymo-table {
  /deep/ .thead {
    color: #fff;
  }
}
.my-item-box2 {
  display: flex;
  flex-direction: column;
}
.title-d {
  padding: 10px 0 15px 15px;
}
.ech-box {
  flex: 1;
  display: flex;
  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > div {
      flex: 1;
    }
    & > div:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
.total-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .my-item-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 16px;
    margin-bottom: 6px;
    position: relative;
    .labe {
      margin-bottom: 4px;
    }
    & > span {
      // padding-left: 16px;
    }
    > div:nth-child(3) {
      margin-top: 7px;
      margin-left: 4px;
      font-family: cheap;
      transform: scaleX(0.5);
      position: absolute;
      left: -4rem;
      width: 100%;
      top: 0;
    }
  }
  .my-item-box:not(:last-child) {
    margin-right: 20px;
  }
  .card-panel-num {
    font-size: 64px;
    color: #f5ef41;
    font-family: cheap;
  }
}
.right-box,
.left-box {
  float: left;
  width: 22%;
}
.center-box {
  flex: 1;
  position: relative;
  max-height: 70vh;
  .area-list {
    position: absolute;
    color: #2262b1;
    display: flex;
    flex-direction: column;
    top: 16%;
    left: 20px;
    line-height: 30px;
  }
}
</style>
